<template>
    <v-container fill-height v-if="show">
        <v-layout align-center justify-center>
            <v-flex xs10 sm8 md8>
                <v-card class="elevation-3" v-if="!gettingRule">
                    <v-toolbar dark color="#777BD2">
                        <v-row no-gutters>
                            <v-col cols="12" sm="6">
                                <v-toolbar-title class="header-heading"><span>Automated Google Sheet Rules</span></v-toolbar-title>
                            </v-col>
                            <v-col cols="12" sm="6" class="text-right">
                                <v-toolbar-title class="header-heading"><span v-if="Rule.id" style="font-size:16px">
                                    Last Modified: {{getMonth(new Date(Rule.updatedAt).getMonth()+1)+'/'+new Date(Rule.updatedAt).getDate()+'/'+new Date(Rule.updatedAt).getFullYear()}}
                                </span></v-toolbar-title>
                            </v-col>
                        </v-row>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <template v-if="!!Rule.id">
                                <v-expansion-panels focusable class="collapsable-details">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>Google Sheet Details</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-text-field 
                                                name="sheetName" 
                                                label="Sheet Name" 
                                                type="text"
                                                v-model="Rule.sheet_name" 
                                                required
                                                color="#777BD2"
                                                :rules="generalRule"
                                                :disabled="!!Rule.id">
                                            </v-text-field>
                                            <v-text-field 
                                                name="sheetID" 
                                                label="Sheet ID" 
                                                type="text"
                                                color="#777BD2"
                                                v-model="Rule.sheet_id" 
                                                required
                                                :rules="generalRule"
                                                :disabled="!!Rule.id">
                                            </v-text-field>
                                            <v-text-field 
                                                name="sheetUrl" 
                                                label="Sheet URL" 
                                                type="url"
                                                color="#777BD2"
                                                v-model="Rule.sheet_url" 
                                                required
                                                :rules="generalRule"
                                                :disabled="!!Rule.id">
                                            </v-text-field>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </template>
                            <template v-if="!Rule.id">
                                <v-text-field 
                                    name="sheetName" 
                                    label="Sheet Name" 
                                    type="text"
                                    color="#777BD2"
                                    v-model="Rule.sheet_name" 
                                    required
                                    :rules="generalRule"
                                    :disabled="!!Rule.id">
                                </v-text-field>
                                <v-text-field 
                                    name="sheetID" 
                                    label="Sheet ID" 
                                    type="text"
                                    color="#777BD2"
                                    v-model="Rule.sheet_id" 
                                    required
                                    :rules="generalRule"
                                    :disabled="!!Rule.id">
                                </v-text-field>
                                <v-text-field 
                                    name="sheetUrl" 
                                    label="Sheet URL" 
                                    type="url"
                                    color="#777BD2"
                                    v-model="Rule.sheet_url" 
                                    required
                                    :rules="generalRule"
                                    :disabled="!!Rule.id">
                                </v-text-field>
                            </template>
                            <v-select
                                :items="templatesList"
                                color="#777BD2"
                                label="Default Email Template"
                                v-model="Rule.default_template_name"
                                :rules="generalRule"
                            ></v-select>
                            <h3>Select the dates on which you want to send out the emails.</h3>
                            <v-row no-gutters>
                                <!-- <v-col cols="12" sm="12">{{Rule.global_send_time}}</v-col> -->
                                <v-col cols="12" sm="6">
                                    <v-date-picker
                                        v-model="googleSheetCalendarDates"
                                        multiple
                                        full-width
                                        :min="calendarMinDate"
                                        color="#777BD2"
                                        show-adjacent-months
                                        event-color="red"
                                        :events="googleSheetCalendarEventDates"
                                    ></v-date-picker>
                                </v-col>
                                <v-col cols="12" sm="6"> 
                                    <v-time-picker
                                        color="#777BD2"
                                        format="ampm"
                                        ampm-in-title
                                        v-model="Rule.global_send_time"
                                    ></v-time-picker>
                                </v-col>
                            </v-row>
                            <h3>City-wise Email Templates</h3>
                            <v-row>
                                <v-col cols="12" sm="2">
                                    Plano
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-select
                                        :items="templatesList"
                                        color="#777BD2"
                                        label="City Email Template"
                                        v-model="Rule.city_wise_rule_data.find(e => e['city'] == 'Plano')['template_name']"
                                        :rules="generalRule"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="2">Frisco</v-col>
                                <v-col cols="12" sm="4">
                                    <v-select
                                        :items="templatesList"
                                        color="#777BD2"
                                        label="City Email Template"
                                        v-model="Rule.city_wise_rule_data.find(e => e['city'] == 'Frisco')['template_name']"
                                        :rules="generalRule"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="2">Dallas Texas</v-col>
                                <v-col cols="12" sm="4">
                                    <v-select
                                        :items="templatesList"
                                        color="#777BD2"
                                        label="City Email Template"
                                        v-model="Rule.city_wise_rule_data.find(e => e['city'] == 'Dallas Texas')['template_name']"
                                        :rules="generalRule"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="2">McKinney</v-col>
                                <v-col cols="12" sm="4">
                                    <v-select
                                        :items="templatesList"
                                        color="#777BD2"
                                        label="City Email Template"
                                        v-model="Rule.city_wise_rule_data.find(e => e['city'] == 'McKinney')['template_name']"
                                        :rules="generalRule"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="2">Allen</v-col>
                                <v-col cols="12" sm="4">
                                    <v-select
                                        :items="templatesList"
                                        color="#777BD2"
                                        label="City Email Template"
                                        v-model="Rule.city_wise_rule_data.find(e => e['city'] == 'Allen')['template_name']"
                                        :rules="generalRule"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="2"></v-col>
                                <v-col cols="12" sm="4"></v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn elevation="0" @click="cancel" class="right-btn">
                            <span>Cancel</span>
                        </v-btn>
                        

                        <v-btn v-if="!!Rule.id" elevation="0" :disabled="rulesActionInProgress" @click="update" dark class="submitBtn primary"><v-progress-circular
                            indeterminate
                            color="#fff"
                            v-if="rulesActionInProgress"
                            class="progressLoader"
                        ></v-progress-circular><span v-if="!rulesActionInProgress">Update</span></v-btn>

                        <v-btn v-if="!Rule.id" elevation="0" :disabled="rulesActionInProgress" @click="submit" dark class="primary"><v-progress-circular
                            indeterminate
                            color="#fff"
                            v-if="rulesActionInProgress"
                            class="progressLoader"
                        ></v-progress-circular><span v-if="!rulesActionInProgress">Save</span></v-btn>
                    </v-card-actions>
                </v-card>
                <template>
                    <div v-if="gettingRule" class="loader">
                        <v-progress-circular
                            indeterminate
                            color="#777BD2"
                            class="progressLoader"
                        ></v-progress-circular>
                    </div>
                </template>
            </v-flex>
        </v-layout>
    </v-container>    
</template>
<script>
export default {
    name: 'RulesManagment',
    data(){
        return{
            userEmail: "",
            oldDates: "",
            newDates: "",
            valid: false,
            show: false,
            generalRule: [
                v => !!v || 'Field is required'
            ],
            calendarMinDate: this.getCalendarMinDate(),
        }
    },
    methods: {
        getTemplates(){
            this.$store.dispatch('getEmailTemplates', this.userEmail);
        },
        getCalendarMinDate(){
            var month = new Date().getMonth()+1;
            var date = new Date().getDate()+1;
            var year= new Date().getFullYear();
            var daysInMonth =  new Date(year, month, 0).getDate();
            if(month < 10){
                month = '0'+month;
            }
            if(date < 10){
                date = '0'+date;
            }
            if(date > daysInMonth){
                date = '01'
            }
            return year+'-'+month+'-'+date;
        },
        getCalendarDates(){
            this.$store.dispatch('getCalendarDates', this.userEmail);
        },
        submit(){
            if (this.$refs.form.validate()) {
                //console.log('submit');
                if(this.googleSheetCalendarDates.length > 0){
                    this.$store.state.rulesActionInProgress = true;
                    var xTime = '1/1/'+(new Date().getFullYear()+1)+' '+this.Rule.global_send_time;
                    var global_send_time = new Date(xTime).getUTCHours()+':'+new Date(xTime).getUTCMinutes();
                    //console.log(global_send_time);
                    var ruleData = 
                    {
                        "user_email": this.userEmail,
                        "default_template_name": this.Rule.default_template_name,
                        "global_send_time": global_send_time,
                        "sheet_name": this.Rule.sheet_name,
                        "sheet_id": this.Rule.sheet_id,
                        "sheet_url": this.Rule.sheet_url,
                        "selected_rule_type": "CITY",
                        "city_wise_rule_data": [
                            {
                                "city": "Plano",
                                "template_name": this.Rule.city_wise_rule_data.find(e => e['city'] == 'Plano')['template_name']
                            },
                            {
                                "city": "Frisco",
                                "template_name": this.Rule.city_wise_rule_data.find(e => e['city'] == 'Frisco')['template_name']
                            },
                            {
                                "city": "Dallas Texas",
                                "template_name": this.Rule.city_wise_rule_data.find(e => e['city'] == 'Dallas Texas')['template_name']
                            },
                            {
                                "city": "McKinney",
                                "template_name": this.Rule.city_wise_rule_data.find(e => e['city'] == 'McKinney')['template_name']
                            },
                            {
                                "city": "Allen",
                                "template_name": this.Rule.city_wise_rule_data.find(e => e['city'] == 'Allen')['template_name']
                            }
                        ],
                        "categegory_wise_rule_data": []
                    };
                    this.saveCalendar();
                    this.$store.dispatch('createRule', ruleData);
                }
                else{
                    this.$store.state.responseMsg = 'Please select a date on calendar';
                    this.$store.state.ResponseClass = 'error';
                }
            }
        },
        getMonth(month){
          if(month<1){
            month = month +12;
          }
          else if (month>12){
            month = month - 12
          }
          return month;
        },
        update(){
            if (this.$refs.form.validate()) {
                //console.log('update');
                if(this.googleSheetCalendarDates.length > 0){
                    this.$store.state.rulesActionInProgress = true;
                    var xTime = '1/1/'+(new Date().getFullYear()+1)+' '+this.Rule.global_send_time;
                    var global_send_time = new Date(xTime).getUTCHours()+':'+new Date(xTime).getUTCMinutes();
                    //console.log(global_send_time);
                    var ruleUpdateData = 
                    {
                        "default_template_name": this.Rule.default_template_name,
                        "sheet_name": this.Rule.sheet_name,
                        "global_send_time": global_send_time,
                        "sheet_id": this.Rule.sheet_id,
                        "sheet_url": this.Rule.sheet_url,
                        "selected_rule_type": "CITY",
                        "city_wise_rule_data": [
                            {
                                "city": "Plano",
                                "template_name": this.Rule.city_wise_rule_data.find(e => e['city'] == 'Plano')['template_name']
                            },
                            {
                                "city": "Frisco",
                                "template_name": this.Rule.city_wise_rule_data.find(e => e['city'] == 'Frisco')['template_name']
                            },
                            {
                                "city": "Dallas Texas",
                                "template_name": this.Rule.city_wise_rule_data.find(e => e['city'] == 'Dallas Texas')['template_name']
                            },
                            {
                                "city": "McKinney",
                                "template_name": this.Rule.city_wise_rule_data.find(e => e['city'] == 'McKinney')['template_name']
                            },
                            {
                                "city": "Allen",
                                "template_name": this.Rule.city_wise_rule_data.find(e => e['city'] == 'Allen')['template_name']
                            }
                        ],
                        "categegory_wise_rule_data": []
                    };
                    this.saveCalendar();
                    this.$store.dispatch('updateRule', {ruleUpdateData : ruleUpdateData, ruleID: this.Rule.id});
                }
                else{
                    this.$store.state.responseMsg = 'Please select a date on calendar';
                    this.$store.state.ResponseClass = 'error';
                }
            }
        },
        saveCalendar(){
            var _this = this, time;
            // eslint-disable-next-line
            this.googleSheetCalendarDates.forEach((value,index) =>{
                let x = this.googleSheetCalendarData.find(e=>e.date_id == value);
                if(x==null){
                    time = value+' '+_this.Rule.global_send_time;
                    //console.log(time);
                    let newObject = {
                        "sheet_name": this.Rule.sheet_name,
                        "sheet_url": this.Rule.sheet_url,
                        "send_time": new Date(time).getTime(),
                        "date_id": value,
                        "confirmation_status": "un-confirmed",
                        "user_email": this.userEmail,
                        "confirmation_time": ((new Date(time).getTime()) - (2 * 24 * 60 * 60 * 1000)),
                        "batch_id": "",
                        "sheet_id": this.Rule.sheet_id,
                        "type": "sheet",
                        "update_status": "n"

                    }
                    this.googleSheetCalendarData.push(newObject);
                }
            });
            // eslint-disable-next-line
            this.googleSheetCalendarData.forEach((value,index) =>{
                let x = this.googleSheetCalendarDates.find(e=> e == value.date_id);
                if(x==null){
                    value['update_status'] = 'd'
                }
            });

            
            /*var xTime = '1/1/'+(new Date().getFullYear()+1)+' '+this.Rule.global_send_time;
            var global_send_time = new Date(xTime).getUTCHours()+':'+new Date(xTime).getUTCMinutes();
            this.$store.dispatch('createCalendarDates', {calendarUpdateData : this.googleSheetCalendarData, userEmail: this.userEmail, global_send_time: global_send_time}); */

            this.$store.dispatch('createCalendarDates', {calendarUpdateData : this.googleSheetCalendarData, userEmail: this.userEmail});
        },
        cancel(){
            this.$router.push({path: '/'});
        }
    },
    mounted(){
        if(this.$store.state.user == null || this.$store.state.user == undefined){
            this.$router.push({path: '/sign-in'});
        }
        else{
            if(this.$store.state.user.attributes['custom:role'] != 'Admin'){
                this.$router.push({path: '/'});
            }
            else{
                this.userEmail = this.$store.state.user.attributes.email;
                this.$store.state.gettingRule = true;
                this.$store.dispatch('getRule', this.userEmail);
                this.$store.dispatch('getCalendarDates', this.userEmail);
                this.$store.dispatch('getEmailTemplates', this.userEmail);
                this.show = true
            }
        }
    },
    computed: {
        templatesList(){
            var x = '';
            var list=[];
            if(this.$store.state.EmailTemplates == undefined || this.$store.state.EmailTemplates == null){
                this.getTemplates();
            }
            else if(this.$store.state.EmailTemplates){
                x = this.$store.state.EmailTemplates.data;
                // eslint-disable-next-line
                x.forEach((value, index) => {
                    list.push(value.name);
                });
            }
            else{
                this.getTemplates();
            }
            return list;
        },
        globalSendTime(){
            //var x = '';
            // var time = this.Rule.global_send_time;
            var time = '9:0';
            if(time){
                //time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

                /*if (time.length > 1) { // If time format correct
                    time = time.slice (1);  // Remove full string match value
                    time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
                    time[0] = +time[0] % 12 || 12; // Adjust hours
                }*/
                //x = time.join (''); // return adjusted time or original string
                console.log(time[0]+time[1]+time[2])
                if(time[0]>1){
                    time = '0'+time;
                }
                if(time[4] != parseInt(time[4])){
                    time= time+'0'
                }
                if(time[0]+time[1]<10 && time[2] == ':'){
                    console.log('yes')
                }
                // if(time[0] < 1){
                //     time[0] = '0'+time[0];
                // }
                // else{

                // }
                console.log(time);
            }
            return time;
            
        },
        campaignTemplate: {
            get()
            {
                return this.$store.state.campaignTemplate;
            },
            set(value)
            {
                this.$store.state.campaignTemplate = value
            }
        },
        rulesActionInProgress(){
            return this.$store.state.rulesActionInProgress;
        },
        gettingRule(){
            return this.$store.state.gettingRule;
        },
        Rule : {
            get(){
                return this.$store.state.Rule;
            },
            set(value){
                this.$store.state.Rule = value
            }
        },
        googleSheetCalendarData:{
            get(){
                return this.$store.state.googleSheetCalendarData;
            },
            set(value){
                this.$store.state.googleSheetCalendarData = value
            }
        },
        googleSheetCalendarDates:{
            get(){
                return this.$store.state.googleSheetCalendarDates;
            },
            set(value){
                this.$store.state.googleSheetCalendarDates = value
            }
        },
        googleSheetCalendarTime:{
            get(){
                return this.$store.state.googleSheetCalendarTime;
            },
            set(value){
                this.$store.state.googleSheetCalendarTime = value;
            }
        },
        googleSheetCalendarEventDates(){
            return this.$store.state.googleSheetCalendarEventDates;
        }
    }
}
</script>
<style scoped>
.v-card.v-sheet{
    padding-bottom: 35px;
}
header.v-sheet.v-toolbar{
    height: auto !important;
}
header .header-heading{
    padding: 25px !important;
    width: 100%;
}
.v-card__text, .v-card__actions{
    padding: 20px 75px 0px;
}
.v-card__actions button.v-btn{
    min-width: 150px;
}
.city-rules tr td:first-child{
    min-width: 150px;
    font-size: 16px;
    font-weight: 500;
}
h3{
    margin: 15px 0;
}
.progressLoader.v-progress-circular{
  height: 100px !important;
  width: 100px !important;
  margin: 15px auto;
}
.submitBtn .progressLoader.v-progress-circular{
    height: 30px !important;
    width: 30px !important;
}
.loader{
    text-align: center;
}
.collapsable-details{
    margin: 10px 0 25px;
}

.v-picker{
    border-radius: 0;
}
.v-picker--time{
    width: 100%;
}
.v-picker--time>>>.v-picker__title{
    padding: 9px !important;
}
.currentTime{
    background-color: #fff;
    padding: 15px;
}
.v-picker>>>.v-picker__body{
    padding: 15px 0px 0px;
}
.v-card__actions .right-btn{
    margin-left: auto !important;
}
</style>